import React from 'react';
import { connect } from 'react-redux';
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import { LayoutPages, Seo } from '../components';
import ServiceDetail from '../components/ServiceDetail/ServiceDetail';
import {JSON_Parse} from "../utils/FormatUtils";

const ServesDetailTemplate = ({ languageSelect, pageContext }) => {
  const breadcrumbs = [
    {
      pathname: '/',
      crumbLabel: `${
        languageSelect === 'ru'
          ? 'Главная'
          : languageSelect === 'en'
          ? 'Home'
          : '首頁'
      }`,
      active: false,
    },
    {
      pathname: '/services/',
      crumbLabel: `${
        pageContext.breadCrumbSubtitle['subtitle_' + languageSelect]
      }`,
      active: false,
    },
    {
      pathname: '',
      crumbLabel: `${pageContext['subtitle_' + languageSelect]}`,
      active: true,
    },
  ];

  return (
    <LayoutPages
      titlePage={pageContext['subtitle_' + languageSelect]}
      breadcrumbs={breadcrumbs}
    >
      <Seo
        title={`${pageContext['subtitle_' + languageSelect]}`}
        description={`${pageContext.seo['description_' + languageSelect]}`}
        keywords={`${pageContext.seo['keywords_' + languageSelect]}`}
      />
      <section className='section service-detail-page'>
        <div className='service-detail'>
          <ServiceDetail
            description={JSON_Parse(
              pageContext['description_' + languageSelect].raw
            )}
            video={pageContext.video.videoShort.file.url}
            videoYoutubeView={
              languageSelect === 'ru' || languageSelect === 'en' ? true : false
            }
            videoYoutubeUrl={
              pageContext.video.video_youTube &&
              pageContext.video.video_youTube.split('/').pop()
            }
            videoDetail={pageContext.video.video_detail.file.url}
            videoPoster={pageContext.videoPoster}
            buttonName={
              pageContext.buttonName['button_order_' + languageSelect]
            }
            formPage={pageContext['subtitle_ru']}
            languageSelect={languageSelect}
          />
        </div>
      </section>
    </LayoutPages>
  );
};

let mapStateToProps = (state) => {
  return {
    languageSelect: state.languageSelect['languageSelect'],
  };
};

export default connect(mapStateToProps)(ServesDetailTemplate);
