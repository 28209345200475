import React, { useEffect, useState } from 'react';
import { BigPlayButton, ControlBar, Player } from 'video-react';
import Responsive from 'react-responsive';

import FormFeedbackModal from '../../modules/FormFeedback/containers/FormFeedbackModal';
import { Modal } from '../index';

const Adp = (props) => <Responsive {...props} maxWidth={767} />;
const NotAdp = (props) => <Responsive {...props} minWidth={768} />;

const ServiceDetail = ({
  description,
  video,
  videoYoutubeView,
  videoYoutubeUrl,
  videoDetail,
  videoPoster,
  buttonName,
  formPage,
  languageSelect,
}) => {
  const mainVideo = React.createRef();

  const [isOpen, setIsOpen] = useState(false);
  const [videoFullScreen, setVideoFullScreen] = useState(false);

  useEffect(() => {
    if (!videoYoutubeView) {
      mainVideo.current.subscribeToStateChange(handleStateChange.bind(this));
    }
  }, [videoYoutubeView]);

  const handleStateChange = (state, prevState) => {
    setVideoFullScreen(state.isFullscreen);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className='container'>
        <div className='service-detail-text'>{description}</div>
        <div className='service-detail-button'>
          <button className='button' onClick={() => setIsOpen(true)}>
            <div className='button-inner'>{buttonName}</div>
          </button>
        </div>
      </div>
      <div className='service-detail-video'>
        <div className='container'>
          <div className='frame service-detail-video__frame'>
            <div className='frame-item frame-item_color_black frame-top'>
              <div className='frame-item__el frame-top__frame-item-el_left'></div>
              <div className='frame-item__el frame-top__frame-item-el_right'></div>
            </div>
            <div className='frame-item frame-item_color_black frame-bottom'>
              <div className='frame-item__el frame-bottom__frame-item-el_left'></div>
              <div className='frame-item__el frame-bottom__frame-item-el_right'></div>
            </div>
          </div>
          <Adp>
            {videoYoutubeView && videoYoutubeUrl ? (
              <iframe
                className={'service-detail-video-youtube'}
                src={`https://www.youtube.com/embed/${videoYoutubeUrl}?autoplay=1&showinfo=0&autohide=1&hl=${languageSelect}`}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            ) : (
              <Player
                autoPlay
                autobuffer
                playsInline={true}
                src={videoDetail}
                muted={!videoFullScreen}
                poster={videoPoster}
                ref={mainVideo}
              >
                <ControlBar
                  autoHide={true}
                  disableDefaultControls={true}
                  disableCompletely={true}
                  className='my-class'
                />
                <BigPlayButton position='center' />
              </Player>
            )}
          </Adp>
          <NotAdp>
            {videoYoutubeView && videoYoutubeUrl ? (
              <iframe
                className={'service-detail-video-youtube'}
                src={`https://www.youtube.com/embed/${videoYoutubeUrl}?autoplay=1&showinfo=0&autohide=1&hl=${languageSelect}`}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            ) : (
              <Player
                muted={!videoFullScreen}
                loop
                autobuffer
                autoPlay
                src={videoDetail}
                ref={mainVideo}
              >
                <ControlBar
                  autoHide={true}
                  disableDefaultControls={false}
                  disableCompletely={false}
                />
              </Player>
            )}
          </NotAdp>
        </div>
      </div>
      <Modal title={buttonName} isOpen={isOpen} onCancel={closeModal}>
        <FormFeedbackModal formPage={formPage} />
      </Modal>
    </>
  );
};

export default ServiceDetail;
